/* #### Generated By: http://www.cufonfonts.com #### */

    @font-face {
    font-family: 'Poppins Regular';
    font-style: normal;
    font-weight: normal;
    src: local('Poppins Regular'), url('../fonts/poppins/Poppins-Regular.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Poppins Thin';
    font-style: normal;
    font-weight: normal;
    src: local('Poppins Thin'), url('../fonts/poppins/Poppins-Thin.woff') format('woff');
    }
    
    @font-face {
    font-family: 'Poppins ExtraLight';
    font-style: normal;
    font-weight: normal;
    src: local('Poppins ExtraLight'), url('../fonts/poppins/Poppins-ExtraLight.woff') format('woff');
    }
    
    @font-face {
    font-family: 'Poppins Light';
    font-style: normal;
    font-weight: normal;
    src: local('Poppins Light'), url('../fonts/poppins/Poppins-Light.woff') format('woff');
    }
    
    @font-face {
    font-family: 'Poppins Medium';
    font-style: normal;
    font-weight: normal;
    src: local('Poppins Medium'), url('../fonts/poppins/Poppins-Medium.woff') format('woff');
    }
   
    @font-face {
    font-family: 'Poppins SemiBold';
    font-style: normal;
    font-weight: normal;
    src: local('Poppins SemiBold'), url('../fonts/poppins/Poppins-SemiBold.woff') format('woff');
    }

    @font-face {
    font-family: 'Poppins Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Poppins Bold'), url('../fonts/poppins/Poppins-Bold.woff') format('woff');
    }
    
    @font-face {
    font-family: 'Poppins ExtraBold';
    font-style: normal;
    font-weight: normal;
    src: local('Poppins ExtraBold'), url('../fonts/poppins/Poppins-ExtraBold.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Poppins Black';
    font-style: normal;
    font-weight: normal;
    src: local('Poppins Black'), url('../fonts/poppins/Poppins-Black.woff') format('woff');
    }