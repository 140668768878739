/* You can add global styles to this file, and also import other style files */

@import '../node_modules/ngx-toastr/toastr.css';

body {
    background: #EFF1FF;
}

h5 {
    text-transform: capitalize;
}

label {
    padding-left: 5px;
    font-size: 12px;
    line-height: 17px;
    font-family: 'Poppins SemiBold';
    text-transform: capitalize;
    color: #666666;
}

.common-table {
    border: 1px solid #D9D9D9;
    background: #fff;
    margin-top: 5px;
}

.common-table thead th {
    background: rgb(59 46 126 / 17%);
    font-size: 13px;
    font-family: 'Poppins Bold';
    color: #3B2E7E;
}

.common-table .light {
    background: #EFF1FF;
    font-size: 13px;
    font-family: 'Poppins Bold';
}

.common-table thead th,
table.common-table tbody td,
.common-table th {
    border-bottom: none;
    padding: 6px 5px;
    font-family: 'Poppins Regular';
    font-size: 13px;
    text-transform: capitalize;
}

.length .dt-input {
    margin-left: 50px;
}

.data-table-download {
    position: absolute;
    top: 7px;
    left: 15px;
    font-size: 11px;
    font-family: 'Poppins SemiBold';
    margin-bottom: 0px;
    text-transform: capitalize;
    z-index: 1;
    cursor: pointer;
}

.newReload {
    position: absolute;
    top: 3px;
    left: 218px;
    font-size: 11px;
    font-family: 'Poppins SemiBold';
    margin-bottom: 0px;
    text-transform: capitalize;
    z-index: 1;
    cursor: pointer;
}

.newReloadNotis {
    position: absolute;
    top: 0px;
    left: 210px;
    font-size: 11px;
    font-family: 'Poppins SemiBold';
    margin-bottom: 0px;
    text-transform: capitalize;
    z-index: 1;
    cursor: pointer;
}

.newReloadNcms {
    position: absolute;
    top: 0px;
    left: 210px;
    font-size: 11px;
    font-family: 'Poppins SemiBold';
    margin-bottom: 0px;
    text-transform: capitalize;
    z-index: 1;
    cursor: pointer;
}

.newReloadTm {
    position: absolute;
    top: 0px;
    left: 225px;
    font-size: 11px;
    font-family: 'Poppins SemiBold';
    margin-bottom: 0px;
    text-transform: capitalize;
    z-index: 1;
    cursor: pointer;
}

table.common-table tbody td {
    color: #202020;
}

table.common-table tbody td.text-right {
    text-align: right;
}

.rightalign-table {
    border: 1px solid #D9D9D9;
    background: #fff;
}

.rightalign-table thead th {
    background: rgb(59 46 126 / 17%);
    font-size: 13px;
    font-family: 'Poppins Bold';
    color: #3B2E7E;
}

.rightalign-table thead th,
table.rightalign-table tbody td,
.rightalign-table th {
    border-bottom: none;
    padding: 6px 5px;
    font-family: 'Poppins Regular';
    font-size: 13px;
    text-align: right;
    text-transform: capitalize;
}

table.rightalign-table tbody td {
    color: #202020;
}

table.rightalign-table tbody td.text-right {
    text-align: right;
}

.rightalign-table tfoot td {
    font-weight: bold;
    font-size: 13px;
    text-align: right;
    padding: 6px 5px;
    background: rgb(46 67 126 / 6%)
}

.hidesearchbox .dataTables_filter {
    display: none;
}

.hidesearchbox .dt-input {
    display: none;
}

.hidentries .dt-input {
    display: none;
}

table.dataTable.common-table,
table.dataTable.rightalign-table {
    border: 1px solid #D9D9D9;
    width: 100% !important;
}

table.dataTable.common-table th {
    background: rgb(59 46 126 / 17%);
    font-size: 13px;
    font-family: 'Poppins Bold';
    color: #3B2E7E;
}

table.dataTable.common-table i {
    color: #3B2E7E;
}

.hidepagination .dt-paging {
    display: none;
}

table.dataTable.common-table.no-footer {
    border-bottom: 1px solid #D9D9D9;
}

table.common-table.dataTable thead th,
table.common-table.dataTable tbody td {
    border-bottom: none;
    padding: 6px 5px;
    font-family: 'Poppins Regular';
    font-size: 13px;
}

table.common-table.dataTable thead th {
    text-transform: capitalize;
    vertical-align: middle;
}

table.common-table.dataTable thead th.column-search {
    vertical-align: bottom;
}

table.dataTable.rightalign-table.no-footer {
    border-bottom: 1px solid #f0f0f0;
}

table.rightalign-table.dataTable thead th,
table.rightalign-table.dataTable tbody td {
    border-bottom: none;
    padding: 6px 5px;
    font-family: 'Poppins Regular';
    font-size: 13px;
}

table.rightalign-table.dataTable thead th {
    text-transform: capitalize;
}

table.rightalign-table.dataTable thead .sorting,
table.rightalign-table.dataTable thead .sorting_asc,
table.rightalign-table.dataTable thead .sorting_desc {
    cursor: pointer;
    cursor: hand;
    background-repeat: no-repeat;
    background-position: right center;
    padding-right: 20px;
}

.nobackground {
    background-image: none !important;
}

.theme .dt-container .dt-input select,
.theme .dt-container .dataTables_filter input {
    background-color: #ffff;
}

.theme .dt-container .dt-paging .dt-paging-button.current,
.theme .dt-container .dt-paging .dt-paging-button.current:hover {
    color: #fff !important;
    border: 1px solid rgb(58 46 126);
    background-color: rgb(58 46 126);
    background: rgb(58 46 126);
    border-radius: 4px;
    padding: 5px 15px;
}

.dt-container .dt-info {
    clear: both;
    float: left;
    padding-top: 0.755em;
    font-size: 13px;
}

.dt-container .dt-paging .dt-paging-button:active,
.dt-container .dt-paging .dt-paging-button:hover {
    outline: none !important;
    background-color: rgb(77 61 168) !important;
    background: rgb(77 61 168) !important;
    box-shadow: none !important;
    border-radius: 4px;
    padding: 5px 15px;
}

.dt-container .dt-paging .dt-paging-button.disabled,
.dt-container .dt-paging .dt-paging-button.disabled:hover,
.dt-container .dt-paging .dt-paging-button.disabled:active {
    background: transparent !important;
    border: 1px solid transparent !important;
}

.dt-container .dt-paging .dt-paging-button {
    padding: 5px 15px !important;
}

.btn-primary,
.btn-primary:focus,
.btn-primary.focus {
    background-color: rgb(58 46 126);
    border: 1px solid rgb(58 46 126);
}

.btn-primary.disabled,
.btn-primary:disabled {
    background-color: rgb(58 46 126);
    border: 1px solid rgb(58 46 126);
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show>.btn-primary.dropdown-toggle {
    color: #fff;
    background-color: rgb(77 61 168);
    border-color: rgb(77 61 168);
}

.btn-primary:hover {
    color: #fff;
    background-color: rgb(77 61 168);
    border-color: rgb(77 61 168);
}

.btn-cancel:hover {
    color: #fff;
    background-color: rgb(77 61 168);
    border-color: rgb(77 61 168);
}

.btn-primary:focus,
.btn-primary.focus {
    box-shadow: 0 0 0 0.2rem rgb(77 61 168 / 45%);
}

.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show>.btn-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgb(77 61 168 / 45%);
}

.btn-cancel {
    color: #3B2E7E;
    background: #ffffff;
    border: 1px solid #3B2E7E;
}

.btn-secondary {
    color: #3B2E7E;
    background: #ffffff;
    border: 1px solid #3B2E7E;
}

.btn.btn-secondary:hover,
.btn.btn-secondary:active,
.btn.btn-secondary:focus {
    background: rgb(58 46 126);
    background-color: rgb(58 46 126) !important;
}

.btn.btn-secondary:focus {
    box-shadow: 0 0 0 0.1rem rgb(58 46 126 / 33%) !important
}

.btn-sm.btn-secondary:hover,
.btn-sm.btn-secondary:active,
.btn-sm.btn-secondary:focus {
    background: rgb(58 46 126);
    background-color: rgb(58 46 126) !important;
}

.theme h1 {
    font-size: 25px;
    font-family: 'Poppins SemiBold';
    color: #2C3592;
    margin-bottom: 0px;
    text-transform: capitalize;
}

h2 {
    font-size: 20px;
    font-family: 'Poppins SemiBold';
    text-transform: capitalize;
}

ul.breadcrumbcustom {
    width: 100%;
    margin-top: 5px;
    margin-bottom: 0px;
    padding-left: 20px;
}

ul.breadcrumbcustom li,
ul.breadcrumbcustom li a {
    color: #959CB6;
    float: left;
    font-size: 13px;
    line-height: 17px;
    letter-spacing: 0.24px;
    font-family: 'Poppins Regular';
    list-style: none;
    font-family: 'Poppins SemiBold';
    text-transform: capitalize;
}

ul.breadcrumbcustom li::after {
    content: "\f105";
    font-family: 'FontAwesome';
    margin: 0px 5px;
}

ul.breadcrumbcustom li.active::after {
    display: none;
}

ul.breadcrumbcustom li:first-child {
    border-left: 1px solid #959CB6;
    padding-left: 10px;
}

ul.breadcrumbcustom li.active,
ul.breadcrumbcustom li.active a {
    color: #3a2e7e
}

.nowrap,
.nowrap td {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.nocase {
    text-transform: none !important;
}

/* .logo {
    margin-top: 0rem;
    margin-bottom: 0rem;
    height: 85px;
} */

.logo {
    margin-top: 0rem;
    margin-bottom: 0rem;
    height: 40px;
    padding-top: 10px;
    
}


.logo_ix {
    margin-top: 0rem;
    margin-bottom: 0rem;
    height: 56px;
    padding: 5px;
}


.carousel-item {
    width: 80%;
}

.carousel-indicators {
    bottom: -30px;
}

.carousel-indicators .active {
    background-color: #EFB41D;
    width: 43px;
    height: 10px;
    border-radius: 10px;
}

.carousel-indicators li {
    width: 10px;
    height: 10px;
    background: #ffffff;
    border-radius: 10px;
    border-bottom: none;
    border-top: none;
}

.full-width {
    width: 100%;
}

.full-height {
    height: 100%;
}

.error {
    color: red;
    margin-left: 5px;
    margin-bottom: 0px;
    font-size: 11px;
    line-height: 15px;
    text-transform: capitalize;
    margin-top: 1px;
    font-family: 'Poppins SemiBold';
}

.red {
    color: #FF0000;
    font-size: 13px;
    line-height: 18px;
}

.grey,
.grey td {
    color: #999 !important;
    line-height: 18px;
}

.green {
    color: #008F30;
    line-height: 18px;
}

.yellow {
    color: #93932d;
    line-height: 18px;

}

.mfont {
    font-size: 13px;
}

.w-100 {
    width: 100%;
}

.height-center {
    display: flex;
    text-align: center;
    height: 100%;
}

.collapsed .rotate-icon {
    transform: rotate(180deg);
}

table.dataTable.common-table i.disabled {
    color: #b7b9bb;
    cursor: not-allowed;
}

ng-autocomplete .material-icons {
    display: none;
}

.alert {
    text-transform: capitalize;
}

.customt30 {
    margin-top: 30px;
}

.card-header {
    background: rgb(83 92 183 / 21%);
    text-transform: capitalize;
}

.card-header h6 {
    color: rgb(58 46 126);
}

.btn {
    text-transform: capitalize;
}

.pointer {
    cursor: pointer;
}

.right-align-usd {
    text-align: right;
    font-size: 13px;
    font-family: 'Poppins Medium';
}

.colorborder {
    border: 1px solid #2c35926e
}

.reload {
    fill: #777777;
    width: 25px;
    height: 25px;
    cursor: pointer;
}

.last-update {
    font-size: 11px;
    font-family: 'Poppins SemiBold';
    margin-bottom: 0px;
    text-transform: capitalize;
}

.data-table-lastupdate {
    position: absolute;
    top: 22px;
    left: 220px;
    font-size: 11px;
    font-family: 'Poppins SemiBold';
    margin-bottom: 0px;
    text-transform: capitalize;
    z-index: 1;
    margin-left: 30px;
}

.data-table-viewmore {
    position: initial;
    color: #3a2e7e !important;
    top: 254px;
    left: 520px;
    font-size: 11px;
    font-family: 'Poppins SemiBold';
    margin-bottom: 0px;
    text-transform: capitalize;
    z-index: 1;
}

.dt-input {
    margin-left: 10px;
    font-family: 'Poppins SemiBold';
    font-size: small;
}

.excel {
    color: rgb(30, 110, 66);
}

.word {
    color: rgb(41, 83, 148);
}

.pdf {
    color: rgb(194, 41, 36);
}

.dt-buttons {
    position: absolute;
    right: -5px;
    top: -25px;
}

.buttons-excel,
.buttons-copy,
.buttons-pdf,
.buttons-csv {
    border: none;
    background: transparent;
    padding: 0px 1px;
}

.buttons-excel::after {
    content: "\f1c3";
    font-family: 'FontAwesome';
    color: rgb(30, 110, 66);
}

.buttons-excel span,
.buttons-copy span,
.buttons-csv span,
.buttons-pdf span {
    display: none;
}

.buttons-copy::after {
    content: "\f0ea";
    font-family: 'FontAwesome';
    color: rgb(0, 0, 0);
}

.buttons-csv::after {
    content: "\f6dd";
    font-family: 'FontAwesome';
    color: rgb(0, 0, 0);
}

.buttons-pdf::after {
    content: "\f1c1";
    font-family: 'FontAwesome';
    color: brown;
}

.dt-buttons {
    position: relative;
    right: -5px;
    /* top: -25px; */
    float: right;
    clear: both;
    top: 5px;
    float: left;
}

.dataTables_filter {
    float: left;
}

.theme-green .bs-datepicker-head {
    background-color: #2c3592 !important;
}

.theme-green .bs-datepicker-body table td.week span {
    color: #2c3592 !important;
    display: none;
}

.theme-green .bs-datepicker-body table td span.selected,
.theme-green .bs-datepicker-body table td.selected span,
.theme-green .bs-datepicker-body table td span[class*=select-]:after,
.theme-green .bs-datepicker-body table td[class*=select-] span:after {
    background-color: #2c3592 !important;
}

.datepicker {
    background-origin: content-box;
}

.datepicker input[type="text"] {
    position: relative;
}

.form-control {
    font-family: 'Poppins Regular';
    font-size: 14px;
}

.toast-container {
    font-family: 'Poppins Regular';
}

.toast-title {
    font-weight: normal !important;
}

.btn,
.btn-sm {
    font-family: 'Poppins Regular';
}

.btn {
    font-size: 14px;
}

[readonly] {
    color: #666666;
}

.ng-autocomplete {
    width: 100% !important;
    padding: 0 !important;
    border: none !important;
}

.autocomplete-container {
    height: auto !important;
    box-shadow: none !important;
}

.autocomplete-container .input-container input {
    height: auto !important;
    line-height: normal !important;
    padding: 7px !important;
    border: 1px solid #ced4da !important;
    border-radius: 4px;
}

.autocomplete-container .suggestions-container ul li a {
    padding: 5px 15px !important;
}

.autocomplete-container .input-container {
    position: relative;
}

.autocomplete-container .input-container::before {
    position: absolute;
    content: " \2304";
    right: 0px;
    height: 20px;
    width: 20px;
}

.bootstrap-select button {
    height: auto !important;
    line-height: normal !important;
    border: 1px solid #ced4da !important;
    border-radius: 4px !important;
    background-color: #ffff !important;
    color: #495057 !important;
    font-size: 13px !important;
}

.bootstrap-select .dropdown-menu li {
    font-size: 13px !important;
}

.bootstrap-select.disabled {
    background-color: #b7b9bb;
}

.bootstrap-select .dropdown-toggle:focus,
.bootstrap-select>select.mobile-device:focus+.dropdown-toggle {
    outline: unset !important;
}

.fillColorGreen {
    fill: #008F30;
}

.fillColorRed {
    fill: #FF0000;
}

.text-right {
    text-align: right;
}

.clearSearch {
    position: absolute;
    right: 30px;
    z-index: 1;
}

.close-position {
    position: absolute;
    right: 5px;
    z-index: 1;
}

textarea {
    resize: none;
}

.customButton {
    content: "\f019";
    position: absolute;
    top: 231px;
    left: 30px;
}

.rejected {
    background-color: #7FFFD4;
}

.confirmed {
    background-color: #00FFFF;
}

.pending {
    background-color: none;
}

.contact {
    color: #3a2e7e;
    font-weight: bold;
}

.cursor {
    cursor: pointer;
}

.cimbank-table thead th,
table.cimbank-table tbody td,
.ricimbankghtalign-table th {
    border-bottom: none;
    padding: 6px 5px;
    font-family: 'Poppins Regular';
    font-size: 13px;
    text-align: left;
    text-transform: capitalize;
}

.usercontrolbody .usercontrol {
    pointer-events: none !important;
}

.usercontrolbody .usercontrol>button {
    background-color: rgb(96 90 129) !important;
    border: 1px solid rgb(204 202 217) !important;
}

.usercontrolbody svg.usercontrol {
    background-color: darkgrey !important;
}

.usercontrolbody i.usercontrol {
    color: darkgrey !important;
}

.usercontrolbody .usercontrol>input {
    background-color: rgb(96 90 129) !important;
    border: 1px solid rgb(204 202 217) !important;
}

.container-fluid {
    position: relative;
}

.hidden {
    display: none !important;
}

.treeview-header .form-check {
    display: none;
}

.fileName {
    font-size: 13px;
    line-height: 10px;
    font-family: 'Poppins SemiBold';
    color: #666666;
}

.pointer {
    cursor: pointer;
}

.divider {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

.folderIcon {
    width: 50px;
}

.fileIcon {
    width: 40px;
}

.treeviewReload {
    position: absolute;
    top: 60px;
    right: 65px;
    font-size: 11px;
    font-family: 'Poppins SemiBold';
    margin-bottom: 0px;
    text-transform: capitalize;
    z-index: 1;
    cursor: pointer;
}

.treeviewClear {
    position: absolute;
    top: 28px;
    right: 50px;
    font-size: 11px;
    font-family: 'Poppins SemiBold';
    margin-bottom: 0px;
    text-transform: capitalize;
    z-index: 1;
    cursor: pointer;
}

.black{
    color: black;
}

.white{
    color: white;
}


div.dt-container select.dt-input {
    padding: 3px !important;
    margin-bottom: 7px !important;
}

div.dt-container .dt-paging .dt-paging-button {
    box-sizing: border-box;
    display: inline-block;
    min-width: 1.5em;
    padding: 0.5em 1em;
    margin-left: 2px;
    margin-top: 10px;
    text-align: center;
    text-decoration: none !important;
    cursor: pointer;
    color: inherit !important;
    border: 1px solid transparent;
    border-radius: 2px;
    background: transparent;
}

div.dt-container .dt-paging{
    float: right !important;
}